export default {
	availableCurrencies(){
		if (this.currencies) {
			let final = []
			let vm = this;
			let activeCurrencies = this.currencies.filter(function(currency) {
				if (vm.request.type == 'buy') {
					return currency.buy_status == 'active' && currency.type!= 'fiat';
				}

				return currency.sell_status == 'active' && currency.type!= 'fiat';
			});

			activeCurrencies.forEach(function(item, index) {
				if (Array.isArray(item.currency_networks) && item.currency_networks.length) {

				    item.currency_networks.forEach(function(network, i) {
				    	let newItem = JSON.parse(JSON.stringify(item))
				    	newItem['name'] = item.name + ' (شبکه ' + network.name + ')';
				    	newItem['network'] = network.id;
				    	newItem['id'] = item.id + '#' + network.id;
				    	final.push(newItem);
				    })
				} else {
					final.push(item)
				}
		    })

			if (this.searchText) {
				final = final.filter((item) => {
					return (item.abbreviation.includes(this.searchText) || item.name.includes(this.searchText));
				})
			}
		    return final;

		}
		return [];
	},
	receiveAmount() {
		if (this.selectedCurrency) {
			let amount = 0;
			if (this.request.type == 'buy') {
				amount = Math.round(this.request.amount * this.selectedCurrency.buy_price, 0);
			} else {
				amount = Math.round(this.request.amount * this.selectedCurrency.sell_price, 0);
			}

			return this.addSeperator(amount);
		}
		return 0;
	},
	
	websiteFee() {
		if (this.selectedCurrency) { 
			for(let fee of this.selectedCurrency.currency_fees) {
				if (this.request.amount >= fee.min_order_amount && (this.request.amount <= fee.max_order_amount || fee.max_order_amount==-1)) {
					return fee.amount;
				}
			}
		}
	},
	// receiveCurrency() {
	// 	if (this.request.receive_currency && this.currencies) {
	// 		let vm = this;
	// 		return this.currencies.find(element => element.abbreviation == vm.request.receive_currency)					
	// 	}
	// 	return '';
	// },
	giveCurrency(){
		if (this.currencies) {
			var abbr = this.request.give_currency;
			return this.selectedNetwork(abbr);
		}
		return '';
		
	},
	receiveCurrency() {
			if (this.currencies) {
				var abbr = this.request.receive_currency;
				return this.selectedNetwork(abbr);
			}
			return '';
	},
	selectedCurrency: {
		get: function() {
			return this.request.type == 'buy'? this.giveCurrency: this.receiveCurrency
		},
		set: function(newValue) {
			if (this.request.type == 'buy') {
				this.request.give_currency = newValue
				return true;
			}
			this.request.receive_currency = newValue;
		}
	},
	availableWallets() {
		if (this.user && this.selectedCurrency && Array.isArray(this.selectedCurrency.currency_networks) && this.request.type == 'sell' && this.wallets) {
			let vm = this;
			return this.wallets.filter(function(item) {
				if ( vm.selectedCurrency.currency_networks.length) {
					let network = vm.receiveCurrency.currency_networks.find(item => item.id == vm.request.receive_curr_network); 

					if (network) {
						return (item.currency.abbreviation == vm.selectedCurrency.abbreviation) && (item.network == network.name)	
					}
				}

				return item.currency.abbreviation == vm.selectedCurrency.abbreviation;
			});
		}
		return ''
	},
	giveAmount: {
		get:function() {
			if (this.request.amount) {
				return this.addSeperator(this.request.amount);
			}
		},
		set: function(newValue) {
			this.request.amount = newValue;
		}

	},
	currencyList() {
		if (this.currencies) {
			return this.currencies.filter(element => element.type != 'fiat');
		}
		return '';
	},
	validate() {
		if (!this.request.give_currency || !this.request.amount) {
			return false;
		}
		return true;
	},
	isDisabled() {
		return !this.validate?'disabled':''
	},
	limitError() {
		if (this.selectedCurrency && this.request.amount) {
			let min = this.request.type+'_min'
			let max = this.request.type+'_max'
			if (parseFloat(this.request.amount) < parseFloat(this.selectedCurrency[min])) {
				return 'مقدار وارد شده حداقل میتواند '+ this.addSeperator(this.selectedCurrency[min], this.selectedCurrency.floating_point).toString()+ ' '+ this.selectedCurrency.name + ' '+' باشد.'
			}

			if (parseFloat(this.request.amount) > parseFloat(this.selectedCurrency[max])) {
				return 'مقدار وارد شده حداکثر میتواند '+ this.addSeperator(this.selectedCurrency[max], this.selectedCurrency.floating_point).toString()+ ' ' +this.selectedCurrency.name + ' '+ ' باشد.'
			}
		}
		return null;
	},
	steps() {
		if (this.selectedCurrency) {
			if (this.selectedCurrency.floating_point) {
				return 1/Math.pow(10,this.selectedCurrency.floating_point);
			}
		}

		return 1;
	},
	formatedUser() {
		if (this.user) {
			let temp = JSON.parse(this.user);
			this.request.email = temp.email;

			return temp;
		}

		return null;
	}
}