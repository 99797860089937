import { makeRequest } from '../utilities/request.js'

export default {

 getCurrencies() {
	let vm = this;
	vm.loader =true;
	makeRequest(this.axiosInstance, 'get', this.urls.currencyIndex)
	.then(resp => {
		vm.loader =false;
		if (resp.status == 'success') {
			vm.currencies = resp.data;
			if (!this.selectedCurrency) {
				this.setDefaults();
			}
		}
	})
	.catch(error => {
		vm.loader =false;
		// alert(error)
	})
},
 getGive(event){
	if (event) {
		this.request.wallet = '';
		if (this.request.type == 'buy') {
			this.request.give_currency=event.abbreviation;
			this.request.give_curr_network=event.network;
			this.request.receive_currency='toman';
			this.request.receive_curr_network='';
			this.onGiveChange(event);
			return true;
		}

		this.request.receive_currency=event.abbreviation;
		this.request.receive_curr_network=event.network;
		this.request.give_currency='toman';
		this.request.give_curr_network='';
		this.getWallets();
		
	}
},
selectWallet(event) {
	if (event) {
		this.request.wallet = event.address
	}
},
getWallets() {
	let vm = this;
	vm.loader =true;
	let args = {};

	makeRequest(this.axiosInstance, 'get', this.urls.wallets)
	.then(resp => {
		vm.loader =false;
		if (resp.status == 'success') {
			vm.wallets = resp.data;
		}
	})
	.catch(error => {
		vm.loader =false;
		// alert(error)
	})
},
setDefaults() {
	this.getGive(this.availableCurrencies[0]);
	this.request.amount = 1;
},
 onGiveChange(event){ 
	return true;
},
 placeOrder(){
	if (this.loader || this.disable || !this.validate) {
		return false;
	}

	if (!this.user || this.user == "null" || this.user == '') { 
		let url = this.baseUrl + this.urls.login;
		window.location= url
		// setTimeout(function(){ }, 3000);
		return false;
	}
	let vm = this;
	this.loader =true;
	makeRequest(this.axiosInstance, 'post', this.urls.create, this.request)
	.then( resp => {
		vm.loader = true;
		if (resp.status == 'success') {
			vm.notification(resp.message, 'success');
			if (resp.action=='submit_form') {
				$('.body').append(resp.data);
				  setTimeout(function(){ $('.form').submit(); }, 3000);
			} else if (resp.action == 'redirect') {
				setTimeout(function(){ window.location=resp.data }, 3000);
			} else {
				setTimeout(function(){ window.location='/orders/show/'+data.order.id }, 3000);
			}
		} else {
			vm.notification(resp.message, 'danger');
		}
		vm.loader = false;
	})
	.catch(error => {
		if (error.response) {
			if(error.response.status == 422){
				for (let message in error.response.data) {
					for (let field of Object.values(error.response.data[message])) {
						vm.notification(field[0], 'danger')
					}
					// console.log(Object.values(error.response.data[message]))
				}
			}else {
				this.notification(error.response.data.message, 'danger')
			}
		} else {
			if (error.message) {

				vm.notification(error.message, 'danger');
			}
		}
		vm.loader = false;
	})
},

 notification(message, type) {
	this.$refs.notification.notify(message,type);
},

 addSeperator(number, floating_point=0, seperator = ',') {
	if (number) {
		number = parseFloat(number);
		if (floating_point && floating_point >= 0) {
			number = number.toFixed(floating_point);
		}
		let parts = number.toString().split('.');
		parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, seperator) ;
		return this.removeTailingZero(parts.join("."))
	}

	return 0;
},

 removeTailingZero(num) {
	num = num.toString();

	if (num.indexOf('.') <= 0) {
		return num;
	}

	return num.replace(/[\.]*0+$|\.$/ig,'');
},

selectType(type) {
	if (type == this.request.type) {
		window.location.href = `/orders/create?type=${this.request.type}&give_currency=${this.request.give_currency}&receive_currency=${this.request.receive_currency}&amount=${this.request.amount}`;
	}
	this.request.type = type;
	let give_currency = this.request.give_currency;
	let give_network = this.request.give_curr_network;
	this.request.give_currency = this.request.receive_currency;
	this.request.give_curr_network = this.request.receive_curr_network;
	this.request.receive_curr_network = give_network;
	this.request.receive_currency = give_currency;
},

selectedNetwork(abbr) {
	var network = this.request.type == 'buy'? this.request.give_curr_network: this.request.receive_curr_network;
	if (network) {
		return this.availableCurrencies.find(element => element.abbreviation == abbr && element.network == network)					
	}
	return this.availableCurrencies.find(element => element.abbreviation == abbr)
}
}

// export {
// 	getCurrencies,
// 	getGive,
// 	onGiveChange,
// 	placeOrder,
// 	notification,
// 	addSeperator,
// 	selectType,
// }