
export const makeRequest = function (axiosInstance, method,url,params=null,headers={}){
	return new Promise((resolve, reject) => {
		axiosInstance[method](url,params,headers)
		.then(resp => {
			if (resp && resp.data.status=='success') {
				resolve(resp.data)
			}else{
				reject(resp.data);
			}
		})
		.catch(err => {
			reject(err)
		})
	})
}
