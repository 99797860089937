<template>
	<div class="container-fluid">
		<div :class="'container-fluid alert alert-'+alertType + ' alert-dismissible fade '+classes" v-if="message"> 
		    <div >
		        <span class="alert-heading small font-weight-bold text-capitalize mr-5"></span>
		        {{ message }}
		    </div>
		    <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		        <span aria-hidden="true">&times;</span>
		    </button> -->
		</div>
	</div>

</template>
<script>
	export default {
		data(){
			return {
				previousMessage:'',
				active:false,
				type:'',
				message:'',
				classes:'hide',
				typingTimer:'',
			}
		},
		computed:{
			title(){
				return this.type=='success'?'موفقیت':'خطا';
			},
			alertType(){
				return this.type=='success'?'success':'danger';
			}
		},
		methods:{
			notify(message, type){
				clearTimeout(this.typingTimer);
				this.type=type;
				this.message= message;
				this.active= true;
				this.classes='show';
				let vm=this;
				this.typingTimer=setTimeout(function(){
				 vm.active=false;
				 vm.classes="fade";
				}, 3000);
			}
		}
	};
</script>
<style>
	.alert {
		border-radius: 0 15px 0 15px;
		width: 100%;

	}

	.alert-primary {
		background: rgba(98, 60, 234, 1);
	}

	.alert-danger {
		background: #e3342f;
	}
</style>