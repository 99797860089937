<template>
	<div class="clearfix component">
		<notification ref="notification"/>

	        <div class="card">
    			<div class="spinner-wrapper" v-if="loader">
    	    		<div class="spinner"></div>
    	    	</div>
	            <!-- <div class="header d-flex justify-content-between">
	                <h2><strong>فرم</strong> خرید و فروش ارز</h2>
	                <span><timer :time="1" @end="getCurrencies"></timer></span>
	            </div> -->
	            <div class="body">
	            	<div class="">
	            		
		                <form v-on:submit.prevent="placeOrder" class="mt-2">
		                	<div class="Currency text-secondary d-flex justify-content-between mt-md-5 mt-1">
		                	    <span>مقدار</span>
		                	    <span>انتخاب ارز</span>
		                	    <span>تومان</span>
		                	</div>
		                	<div class="Currency bg-light d-flex justify-content-between">
		                	    <input class="form-control w-25 align-middle form-control-sm border-0 text-center" type="text"
		                	        v-model="request.amount">
		                	    <a href="#" class="Currency-selection" data-toggle="collapse" data-target="#myDIV" aria-expanded="false">
		                	        <img :src="selectedCurrency?selectedCurrency.icon:''" alt="">
		                	    </a>

		                	    <div id="myDIV" class="rounded collapse">
		                	        <div class="bg-white text-center p-4">
		                	            <p class="text-secondary font-weight-bold">ارز مورد نظر را انتخاب کنید</p>
		                	            <div class="d-flex justify-content-center">
			                	            <div class="col-md-8 mb-3">
			                	            	<input type="text" class="form-control" v-model="searchText" placeholder="جستجو بر اساس نام یا اختصار ارز">
			                	            </div>
		                	            </div>
		                	            <div class="row currency-list">

		                	                <div class="col-md-4 col-6 mb-2" v-for="currency of availableCurrencies">
		                	                    <a :class="'d-flex Currency-selection-type bg-light ' + ((selectedCurrency &&selectedCurrency.abbreviation==currency.abbreviation)?'selected':'')"  @click="getGive(currency)" href="#">
		                	                        <img class="my-auto" :src="currency.icon" alt="">
		                	                        <div class="py-2 px-2">
		                	                            <h6 class="my-auto font-weight-bold">
		                	                                {{ currency.name }}
		                	                            </h6>
		                	                            <p class="my-auto">
		                	                                1 {{ currency.abbreviation }} = {{ request.typ=='buy'?currency.buy_price:currency.sell_price }} تومان
		                	                            </p>
		                	                        </div>
		                	                    </a>
		                	                </div>
		                	            </div>
		                	        </div>

		                	    </div>
		                	    <input class="form-control w-25 align-middle form-control-sm border-0 text-center" type="text" :value="receiveAmount" disabled="">
		                	</div>
		                	<div class="Transaction-btn d-flex justify-content-center mt-5">
		                	    <a  :class="'btn btn-right btn-lg '+(request.type=='sell'?'selected':'')" @click="selectType('sell')">درخواست خرید <img class="d-sm-inline d-none" src="/landing/koinee/icons/buy.svg" alt=""></a>
		                	    <a  :class="'btn btn-left btn-lg '+ (request.type=='buy'?'selected':'')" @click="selectType('buy')"> <img class="d-none d-sm-inline ml-2" src="/landing/koinee/icons/sell.svg" alt="">درخواست فروش</a>
		                	</div>
	                	</form>

                	</div>
                    <!-- <button type="button" class="btn btn-raised btn-primary btn-round waves-effect">ثبت سفارش</button> -->
	            </div>
	        </div>

	</div>
</template>
<script>
import '../dependencies/bootstrap-colorpicker.js'
import '../dependencies/jquery.inputmask.bundle.js'
import '../dependencies/jquery.multi-select.js'
import '../dependencies/jquery.spinner.js'
import '../dependencies/bootstrap-tagsinput.js'
// import '../dependencies/jquery-jvectormap-us-aea-en.js'
import '../dependencies/nouislider.js'

import axios from 'axios'
import time from './time.vue'
import Notification from "./Notification"
import methods from '../utilities/methods.js'
import computeds from '../utilities/computeds.js'

export default {
	props:['baseUrl', 'user', 'exchange'],
	components:{
		timer:time,
		notification:Notification
	},
	data() {
		return {
			urls:{
				currencyIndex:'/currencies',
				create:'/orders/create',
				login:'/login'
			},
			currencies:'',
			disable: false,
			give_amount:0,
			receive_amount:0,
			loader:false,
			searchText:'',
			request:{
				give_currency:'',
				amount:'',
				receive_amount:0,
				receive_currency:'toman',
				type:'buy', 
				give_curr_network:'',
				receive_curr_network:'',
			},
			axiosInstance:axios.create({
            	baseURL:this.baseUrl,
            })
		}
	},
	mounted() {
		this.getCurrencies();
	}, 
	computed: {
		...computeds
	},
	methods: {
		...methods
	}
};
</script>
<style >


	.component {
		position: relative;
	}
	.btn, .navbar .navbar-nav > a.btn {
		line-height: 2em;
	}
	ul.vs__dropdown-menu li{
		direction:rtl;
		text-align: right;
	}
	.vs__dropdown-toggle {
		padding: 7px 10px;
		border: 1px solid #E3E3E3;
		border-radius: 30px;
	}
	.list-style-type-none {
		list-style-type: none;
	}
	.spinner-wrapper {
  	  position: absolute;
	  z-index: 9999;
	  text-align: center;
	  background: #ccbbec;
	  height:100%;
	  width:100%;
	  top: 0;
	  right: 0;
	  left: 0;
	  bottom: 0;
	  opacity: 0.8;
	  transition: display 2s;
	}

	.spinner {
		position: relative;
		border: 10px solid #f3f3f3; /* Light grey */
		border-top: 10px solid #3498db; /* Blue */
		border-radius: 50%;
		width: 60px;
		height: 60px;
		top: 38%;
		right: 45%;
		animation: spin 2s linear infinite;
	}

	.radius-10 {
		border-radius: 10px;
	}

	.vs__clear {
		margin-left: 8px;
	}
	
	.alert-dismissible {
		padding-right: 0;
	}

	.card .header h2 {
	    font-size: 15px;
	    color: #757575;
	    position: relative;
	}

	.card .header h2 strong {
		color: #a27ce6;
	}
	.v-select .vs__actions {
		cursor: pointer;
	}
	#app .card {
		font-size: 14px;
		color: #424242;
		font-weight: 400;
		background: #fff;
	    transition: .5s;
	    border: 0;
	    position: relative;
	    width: 100%;
	}

	#app .card .header {
		color: #424242;
	    padding: 20px;
	    position: relative;
	    box-shadow: none;
	}

	#app .form-group .form-control {
		padding: 10px 18px 10px 18px;
	    box-shadow: none;
	    background-color: rgba(0, 0, 0, 0);
	    border: 1px solid #E3E3E3;
	    border-radius: 30px;
	    color: #2c2c2c;
	    font-size: .8571em;
	    height: auto;
	    line-height: normal;
	}

	#app .alert.alert-primary {
		background-color: rgba(242, 67, 9, 0.8);
	}

	#app .alert.alert-warning {
	    background-color: rgba(255, 159, 5, 0.8);
	}

	#app .alert {
		border: 0;
		border-radius: .55rem;
		color: #fff;
		padding-top: .9rem;
		padding-bottom: .9rem;
		position: relative;
	}

	/*#app .btn {
		font-size: 12px;
	}*/

	#app .alert-info {
		background-color: rgba(44, 168, 255, 0.8);
	} 

	#myDIV {
		max-height: 350px;
	}

	#myDIV .currency-list {
		max-height: 200px;
		overflow: auto;
	}
	.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
		border-top-right-radius: 30px;
	    border-bottom-right-radius: 30px;
    	border-top-left-radius: 0;
        border-bottom-left-radius: 0;
		
	}

	.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
		border-top-right-radius: 0;
	    border-bottom-right-radius: 0;
    	border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
	}

	.selected-type {
		box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); 
	}

	input.form-control {
	     border-radius: 5px; 
	     padding: 5px; 
	}

	.btn.selected {
		box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
	}

	@keyframes spin {
	  0% { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
	}
</style>