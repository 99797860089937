<template>
	<div  class="time-section">
		<span>به روز رسانی قیمت‌ها</span>
		<div class="badge badge-primary" style="direction: ltr;">
			<vue-countdown-timer
			@end_callback="getCurrencies"
			:start-time="startTime"
			:end-time="endTime"
			:interval="1000"
			:day-txt="null"
			:hour-txt="null"
			:start-label="'Until start:'"
			label-position="begin"
			>
			<template slot="countdown" slot-scope="scope">
				<span>{{scope.props.minutes}}</span>:
				<span>{{scope.props.seconds}}</span>
			</template>
		</vue-countdown-timer>
       </div>
	</div>
</template>
<script>
	export default {
		props:['time', ],
		data() {
			return {
				startTime: 0,
				endTime:0,
			}
		},
		mounted() {
			this.setTime();
		},
		methods: {
			setTime() {
				this.startTime= (new Date()).getTime();
				this.endTime = this.startTime + this.time * 60000;
			},
			getCurrencies() {
				this.$emit('end')
				this.setTime();
			}
		}
	};
</script>
<style>
	.time-section {
		font-size: 0.9em;
	    opacity: 0.5;
	}
	.badge {
		width: 50px;
		padding: 4px 8px;
		text-transform: uppercase;
		font-size: .7142em;
		line-height: 12px;
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid;
		font-weight: 400;
	}

	.badge-primary {
		border-color: #1ab1e3;
		color: #1ab1e3;
	}
</style>
