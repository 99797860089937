window.Vue = require('vue');
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueCountdownTimer from 'vuejs-countdown-timer'
// import "../css/morris.css"
// import "../css/bootstrap-colorpicker.css"
// import '../css/main.css'
// import '../css/color_skins.css'

Vue.use(VueCountdownTimer)
Vue.component("v-select", vSelect);

Vue.component('calculator', require('./components/KoineeCalculator.vue').default);
Vue.component('notification', require('./components/Notification.vue').default);


const app = new Vue({
    el: '#app',
});
